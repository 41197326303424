import React from "react"
import { getCourseNavigation } from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"
import CourseFooterNext from "../../../../../components/course-footer-next"
import Heading from "../../../../../components/heading"
import Note from "../../../../../components/note"
import PaperStack from "../../../../../components/paper-stack"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"
import TaskSidebar from "../../../../../components/task-sidebar"
import PoemHaelfteDesLebens from "../../../../../content/kurse/haelfte-des-lebens/01-zwei-haelften/poem-haelfte-des-lebens"

const Page = () => {
  const navigation = getCourseNavigation({ courseId: "haelfte-des-lebens" })

  return (
    <LayoutCourse
      footer={
        <CourseFooterNext to="/kurse/haelfte-des-lebens/01-zwei-haelften/spiel-mit-versgrenzen/info-2" />
      }
      navigation={navigation}
    >
      <Seo title="Versgrenzen setzen" />
      <Stack>
        <Heading as="h2" level={2}>
          Versgrenzen setzen
        </Heading>
        <TaskSidebar
          main={
            <PaperStack>
              <PoemHaelfteDesLebens showTitle />
            </PaperStack>
          }
          sidebar={
            <Note variant="task">
              Sieh dir die Versgrenzen der beiden Strophen noch einmal genauer
              an. Fällt dir dabei etwas auf?
            </Note>
          }
        />
      </Stack>
    </LayoutCourse>
  )
}

export default Page
